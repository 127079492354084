import { API_URL } from '../../../constants'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePublicEvent, fetchPublicEvents, updatePublicEvent } from '../../../redux/modules/PublicEvents/actions';
import { fetchPublicEventRegistrations } from '../../../redux/modules/PublicEventRegistrations/actions';
import { fetchPublicEventSpeakers } from '../../../redux/modules/PublicEventSpeakers/actions';
import { generateSympoisumSpreadsheet } from '../../generateEventRegistrantSpreadsheet';
// import { generateRegistrantSpreadsheet } from '../../generateEventRegistrantSpreadsheet';

const EventsPage = ( { adminRole } ) => {

	const [ loggedInAsBasicUser, loggedInAsSuperUser ] = [ adminRole > 1, adminRole === 0 ];

	const dispatch = useDispatch();

	useEffect( () => dispatch( fetchPublicEvents() ), [ dispatch ] );

	const [ editEventFormState, setEditEventFormState ] = useState( null );
	const [ editEventCoordinates, setEditEventCoordinates ] = useState( null );
	const [ editMessage, setShowEditMessage ] = useState( null );

	const publicEvents = useSelector( state => state.publicEvents );
	// const publicEventRegistrations = useSelector( state => state.publicEventRegistrations );

	const flashMessage = message => {
		setShowEditMessage( message );
		setTimeout( () => setShowEditMessage( null ), 3000 );
	};

	const setEventToEdit = event => {
		setEditEventFormState( event );
		if ( event?.coordinates ) {
			const [ lat, lng ] = event?.coordinates.split( ',' ).map( coordinate => parseFloat( coordinate ) );
			setEditEventCoordinates( { lng, lat } );
		} else setEditEventCoordinates( null );
	};

	const downloadSymposiumRegistrations = ( { target } ) => {
		fetch( `${ API_URL }/symposium_registrations/${ target.value }` ).then( response => response.json() ).then(
			registrantData => generateSympoisumSpreadsheet( target.value, registrantData )
		);
	};

	const handleEditEventFormChange = ( { target } ) => setEditEventFormState( { ...editEventFormState, [ target.name ]: target.value } );

	const handleEditEventFormSubmit = formSubmissionEvent => {
		formSubmissionEvent.preventDefault();
		dispatch( updatePublicEvent( editEventFormState.id, {
			...editEventFormState,
			start_time: editEventFormState.start_date ? `${ new Date( editEventFormState.start_date ).toISOString().split( 'T' )[ 0 ] }T${ new Date( editEventFormState.start_time ).toISOString().split( 'T' )[ 1 ] }` : null,
			end_time: editEventFormState.end_date ? `${ new Date( editEventFormState.end_date ).toISOString().split( 'T' )[ 0 ] }T${ new Date( editEventFormState.end_time ).toISOString().split( 'T' )[ 1 ] }` : null,
			coordinates: editEventCoordinates ? Object.values( editEventCoordinates ).join() : null
		} ) );
		flashMessage( 'Changes saved.' );
		setEventToEdit( null );
	};

	const handleRegistrationDownload = publicEvent => {
		dispatch( fetchPublicEventRegistrations( publicEvent ) );
		// generateRegistrantSpreadsheet( publicEvent.name.slice( 0, 10 ), publicEventRegistrations );
	};

	const handleSpeakersDownload = publicEvent => {
		dispatch( fetchPublicEventSpeakers( publicEvent ) );
		// generateRegistrantSpreadsheet( publicEvent.name.slice( 0, 10 ), publicEventRegistrations );
	};

	const handleEventDelete = id => {
		const confirmDeletion = window.confirm( 'Are you sure you want to delete this event?' );
		if ( confirmDeletion ) {
			dispatch( deletePublicEvent( id ) );
			flashMessage( 'Event deleted.' );
			setEventToEdit( null );
			dispatch( fetchPublicEvents() );
		}
	};

	return <div style={ { fontFamily: 'Gotham' } }>

		{ editMessage && <div style={ { position: 'absolute', top: 10, right: 10, padding: '10px', backgroundColor: '#f00', color: 'white', fontWeight: 600 } }>{ editMessage }</div> }

		<h1>Events</h1>

		{ !loggedInAsBasicUser && <a href='/admin/events/create'>Create new event</a> }

		{ editEventFormState && <form
			style={ { display: 'flex', flexFlow: 'column nowrap' } }
			onSubmit={ handleEditEventFormSubmit }
		>

			<div>
				<label htmlFor='name'>Event name:&nbsp;</label>
				<input name='name' value={ editEventFormState?.name || '' } required onChange={ handleEditEventFormChange }></input>
				&nbsp;
				<label htmlFor='location'>Location name:&nbsp;</label>
				<input name='location' value={ editEventFormState?.location || '' } onChange={ handleEditEventFormChange }></input>
			</div>

			<div>
				<label htmlFor='start_date'>Event start:&nbsp;</label>
				<input name='start_date' type='date' value={ editEventFormState?.start_date || '' } required onChange={ handleEditEventFormChange }></input>
				&nbsp;
				<input name='start_time' type='time' value={ editEventFormState?.start_time || '' } onChange={ handleEditEventFormChange }></input>
				&nbsp;
				<label htmlFor='start_date'>Event end:&nbsp;</label>
				<input name='end_date' type='date' value={ editEventFormState?.end_date || '' } onChange={ handleEditEventFormChange }></input>
				&nbsp;
				<input name='end_time' type='time' value={ editEventFormState?.end_time || '' } onChange={ handleEditEventFormChange }></input>
			</div>

			<div>
				<label htmlFor='description'>Event description:&nbsp;</label>
				<textarea name='description' cols='50' value={ editEventFormState?.description || '' } onChange={ handleEditEventFormChange }></textarea>
			</div>

			<div>
				<label htmlFor='zoom_id'>Zoom ID:*&nbsp;</label>
				<input name='zoom_id' value={ editEventFormState?.zoom_id || '' } maxLength={ 11 } onChange={ handleEditEventFormChange }></input>
			</div>

			<div>
				<label htmlFor='virtual'>Virtual?&nbsp;</label>
				<input name='virtual' type='checkbox' checked={ editEventFormState?.virtual } onChange={ () => setEditEventFormState( { ...editEventFormState, checked: !editEventFormState?.checked } ) }></input>
				&nbsp;
				<label htmlFor='link_url'>Add a link:&nbsp;</label>
				<input name='link_url' value={ editEventFormState?.link_url || '' } onChange={ handleEditEventFormChange }></input>
				&nbsp;
				<label htmlFor='img_url'>Add an image:&nbsp;</label>
				<input name='img_url' value={ editEventFormState?.img_url || '' } onChange={ handleEditEventFormChange }></input>
			</div>

			<div>
			<label htmlFor='longitude'>Longitude:&nbsp;</label>
				<input name='longitude' value={ editEventCoordinates?.lat } onChange={ ( { target } ) => setEditEventCoordinates( { lat: parseFloat( target.value ), lng: editEventCoordinates?.lng } ) }></input>
				<label htmlFor='latitude'>Latitude:&nbsp;</label>
				<input name='latitude' value={ editEventCoordinates?.lng } onChange={ ( { target } ) => setEditEventCoordinates( { lat: editEventCoordinates?.lat, lng: parseFloat( target.value ) } ) }></input>
			</div>

			<div>
				<button onClick={ () => setEventToEdit( null ) }>Cancel</button>
				<input type='submit' value='Save changes' />
			</div>

			<br /><br />
			<div style={ { color: 'red' } }>* - Zoom ID field MUST be either left blank or filled in with a valid 11-digit Zoom Meeting ID!</div>

		</form> }

		<hr />

		{ publicEvents.length ? <div style={ { display: 'flex', flexFlow: 'row wrap', gap: '10px' } }>
			{ publicEvents.map( publicEvent => <div key={ publicEvent.id } style={ { display: 'flex', flexFlow: 'column nowrap' } }>
				<div>{ publicEvent.name }</div>
				<div>({ publicEvent.location })</div>
				<button onClick={ () => setEventToEdit( publicEvent ) }>Edit</button>
				<button onClick={ () => handleRegistrationDownload( publicEvent ) }>Download registrations</button>
				{ [ 5, 16, 17, 18 ].includes( publicEvent.id ) && <button onClick={ () => handleSpeakersDownload( publicEvent ) }>Download speakers</button> }
				{ loggedInAsSuperUser && <button onClick={ () => handleEventDelete( publicEvent.id ) }>Delete</button> }
			</div> ) }
		</div> : <span>No public events to display.</span> }

		<hr />
		<h2>Download Annual Symposium registrants</h2>
		<label htmlFor='symposium_registrations_download'>Year to download:&nbsp;</label>
		<select 
			name='symposium_registrations_download' 
			defaultValue=''
			onChange={ downloadSymposiumRegistrations }
		>
			<option value='' disabled>Select a year</option>
			{ [ 2023, 2024, 2025 ].map( year => <option key={ year } value={ year }>{ year }</option>) }
		</select>

	</div>;

};

export default EventsPage;
